import React from "react";
import "../styles/App.css";
import Chat from "./chat/Chat";

import 'react-toastify/dist/ReactToastify.css';
import "@flaticon/flaticon-uicons/css/all/all.css";

function App() {
    return (<><Chat/></>);
}

export default App;
