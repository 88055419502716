import React from "react";
import Sidebar from "./Sidebar";
import {useState, useRef, useEffect} from "react";
import "../../styles/chat.css";
import Linkify from "react-linkify";
import BubbleLogo from "../../assets/images/bubble.png"
import SUN from "../../assets/images/sun.svg";
import MOON from "../../assets/images/moon.svg";
import {v4 as uuidv4} from 'uuid';
import { socket } from '../../socket';

function Chat() {

    const chatContainerRef = useRef(null);

    const [sendInput, setSendInput] = useState("");

    const [messages, setMessages] = useState([]);

    const [isInitialLoading, setIsInitialLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isDark, setIsDark] = useState(false);

    const [screenSize, setScreenSize] = useState(window.innerWidth);


    useEffect(() => {
        socket.on('message', (msg) => {
            addBotMessage(msg.content, uuidv4())
        });

        return () => {
            socket.off('message');
        };
    }, []);


    useEffect(() => {
        const handleWindowResize = () => {
            setScreenSize(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const isDarkModeStored = localStorage.getItem("isDarkMode");

        if (isDarkModeStored) {
            setIsDark(isDarkModeStored === "true");
        }
    }, []);


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    }, [messages]);


    const addUserMessage = (content) => {
        const userMessage = {role: "user", content: content};
        setMessages((prevChat) => [...prevChat, userMessage]);
        setIsLoading(true);
    };

    const addBotMessage = (content, messageId, rate = "", provider = "") => {
        const botResponse = {
            role: "assistant",
            content: content,
            messageId: messageId,
            rate: rate,
            provider: provider
        };

        setMessages((prevChat) => [...prevChat, botResponse]);
        setIsLoading(false);
    };

    const handleEnterPress = (e) => {
        if (e.key === "Enter" && !isLoading) {
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        if (sendInput) {
            addUserMessage(sendInput)
            socket.send(sendInput);
            setSendInput('');
        }
    }


    const handleInputChange = (event) => {
        const userInput = event.target.value;

        setSendInput(userInput);

    };


    const toggleDarkMode = () => {
        const newIsDark = !isDark;
        setIsDark(newIsDark);
        localStorage.setItem("isDarkMode", newIsDark.toString());
    };


    return (
        <div className={`container ${isDark ? "dark" : ""} `}>

            <div
                className={`main`}
            >
                <i
                    className={`fi fi-br-menu-burger burgerMenu hidden ${
                        isDark ? "text-white" : ""
                    }`}
                ></i>

                <label className="theme-toggle-container">
                    <input
                        className="theme-toggle-checkbox"
                        type="checkbox"
                        checked={!isDark}
                        onChange={toggleDarkMode}
                    ></input>
                    <div className="theme-toggle-slot">
                        <div className="sun-icon-wrapper">
                            <img src={SUN} alt="sun"/>
                        </div>
                        <div className="theme-toggle-button"></div>
                        <div className="moon-icon-wrapper">
                            <img src={MOON} alt="moon"/>
                        </div>
                    </div>
                </label>
                <div
                    className="chats"
                    ref={chatContainerRef}
                >
                    {isInitialLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <>
                            <div>
                                {messages?.map((message, index) => (

                                    <div key={`${index}-${message.messageId}-${message.rate}`}
                                         className={`chat-message ${message.role === "user" ? "user" : `bot `}`}>
                                        <div
                                            className={`chat-message-content ${isDark ? "darkBackground" : "lightBackground"}`}>
                                            {message.role !== 'user' && (
                                                <img src={BubbleLogo}
                                                     alt="userPhoto" className="avatar"/>
                                            )}


                                            <p className={`text ${isDark ? "text-white" : ""}`}>

                                                {message.content}

                                            </p>
                                        </div>
                                        <div className="chat-message-footer">
                                            {message?.provider && <span
                                                className="chat-message-footer provider">{message.provider}</span>}
                                        </div>
                                    </div>
                                ))}

                                {isLoading &&
                                    (<div className={`chat-message bot`}>
                                            <div
                                                className={`chat-message-content ${isDark ? "darkBackground" : "lightBackground"}`}>
                                                <img src={BubbleLogo} className="avatar" alt="avatar"/>
                                                <div id="wave">
                                                    <span
                                                        className={`dot ${
                                                            isDark ? "dotLightColor" : "dotGrayColor"
                                                        }`}
                                                    ></span>
                                                    <span
                                                        className={`dot ${
                                                            isDark ? "dotLightColor" : "dotGrayColor"
                                                        }`}
                                                    ></span>
                                                    <span
                                                        className={`dot ${
                                                            isDark ? "dotLightColor" : "dotGrayColor"
                                                        }`}
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <div></div>
                        </>
                    )}
                </div>
                <div className="chatFooter">

                    <div className={`send-input-container ${isDark ? "darkInput" : ""}`}>
                        <input
                            type="text"
                            placeholder="მომწერე ქართულად..."
                            value={sendInput}
                            onKeyDown={handleEnterPress}
                            onChange={handleInputChange}
                            className={`send-input ${
                                isDark ? "lightInput send-input-gray-placeholder" : ""
                            }`}
                        />
                        <button
                            className={`send ${isLoading ? "" : "sendColor"}`}
                            onClick={handleSendMessage}
                            disabled={isLoading}
                        >
                            <i className="fi fi-ss-paper-plane"></i>
                        </button>
                    </div>
                    {/*{screenSize > 1024 ? (*/}
                    {/*    <p className={`${isDark ? "white-footer-text" : "black-footer-text"}`}>*/}
                    {/*        ბოტმა შესაძლოა დააგენერიროს თქვენ მიერ დასმული კითხვის*/}
                    {/*        კონტექსტიდან განსხვავებული/შეუსაბამო პასუხები*/}
                    {/*    </p>*/}
                    {/*) : (*/}
                    {/*    <p className={`${isDark ? "white-footer-text" : "black-footer-text"}`}>*/}
                    {/*        ბოტმა შესაძლოა დააგენერიროს კონტექსტიდან შეუსაბამო პასუხები*/}
                    {/*    </p>*/}
                    {/*)}*/}
                </div>
            </div>
        </div>
    );
}

export default Chat;
